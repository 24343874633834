<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Playbook <span>Header Setting</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()">
                    <i class="fas fa-long-arrow-alt-right"></i>
                </button>
            </div>
            <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" class="playbook-header-setting-form">
                <div class="modal_body pt-4">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Company Name</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.company_branding }">
                                    <Field autocomplete="off" type="text" name="company_branding" v-model="form.company_branding" placeholder="ex: Superfit Coaching" />
                                </div>
                                <ErrorMessage name="company_branding" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Header Background Color</label>
                                <Field autocomplete="off" name="header_bgcolor" v-model="form.header_bgcolor" type="text" label="element color">
                                    <color-picker v-model="form.header_bgcolor" :classes="{ 'has-error': errors.header_bgcolor }" />
                                </Field>
                                <ErrorMessage name="header_bgcolor" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Header Text Color</label>
                                <Field autocomplete="off" name="header_textcolor" v-model="form.header_textcolor" type="text" label="element color">
                                    <color-picker v-model="form.header_textcolor" :classes="{ 'has-error': errors.header_textcolor }" />
                                </Field>
                                <ErrorMessage name="header_textcolor" class="text-danger" />
                            </div>
                        </div>
                        <h3 class="sub_header2 mt-3">Header Logo</h3>
                        <image-library v-model="form.company_logo" image-type="public-logo" upload-text="Logo" />
                        <h3 class="sub_header2 mt-5">Header Author Image</h3>
                        <image-library v-model="form.author" image-type="avatar" upload-text="Logo" />
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="updateLoader" class="btn cancel_btn" type="button" @click="closeModal()">Cancel</button>
                    <button :disabled="updateLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="updateLoader"></i> {{ updateLoader ? 'Saving' : 'Save' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ColorPicker = defineAsyncComponent(() => import('@/components/ColorPicker'))
    const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))

    export default {
        name: 'Playbook Header Setting',

        data () {
            return {
                form: {
                    company_branding: '',
                    header_bgcolor: '',
                    header_textcolor: '',
                    company_logo: '',
                    author: '',
                },
            };
        },

        props: {
            modelValue: Boolean,
            playbook: Object,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            ColorPicker,
            ImageLibrary,
        },

        watch: {
            modelValue () {
                const vm = this;

                vm.resetForm();
            },
        },

        computed: mapState({
            updateLoader: state => state.playbookModule.playbookUpdateLoader,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                updatePlaybookSetting: 'playbookModule/updatePlaybookSetting',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    playbook_id: vm.playbook.id,
                    company_branding: vm.playbook.company_branding ? vm.playbook.company_branding : '',
                    header_bgcolor: vm.playbook.header_bgcolor ? vm.playbook.header_bgcolor : '',
                    header_textcolor: vm.playbook.header_textcolor ? vm.playbook.header_textcolor : '',
                    company_logo: vm.playbook.company_logo ? vm.playbook.company_logo : '',
                    author: vm.playbook.author ? vm.playbook.author : '',
                };
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = JSON.parse(JSON.stringify(vm.form));

                params.setFieldError = setFieldError;

                vm.updatePlaybookSetting(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },
        },
    }
</script>

<style scoped>
    :deep(.playbook-header-setting-form) {
        height: calc(100vh - 180px);
    }

    :deep(.playbook-header-setting-form .group_item) {
        width: 100%;
    }

    /* :deep(.form_grp .group_item label.input_label) {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
    } */
</style>
